import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import {
  mainRoutes,
  loginRoutes,
  homePageRoutes,
  contentPageRoutes,
  noLayoutRoutes,
  // signUpRoutes,
} from "config/routing";
// import AppLayout from "layouts/MainLayout";
import { PreloaderComponent } from "components/_common/Preloader";
import Error404 from "Pages/error/Error404";
import HomePageLayout from "layouts/HomePageLayout";
import SignInLayout from "layouts/SignInLayout";
import SignUpLayout from "layouts/SignUpLayout";
import AuthLayout from "layouts/AuthLayout";
import ContentPageLayout from "layouts/ContentPageLayout";
interface NestedRoute {
  path: string;
  element: React.ReactElement;
}
interface MainRoute {
  path: string;
  element: React.ReactElement;
  routes?: Record<string, NestedRoute>;
}

export default function RouteWrapper() {
  const routeKeys = Object.keys(mainRoutes);
  // const signUpRouteKeys = Object.keys(signUpRoutes);
  const loginRouteKeys = Object.keys(loginRoutes);
  const homePageRoutesKeys = Object.keys(homePageRoutes);
  const contentPageRoutesKeys = Object.keys(contentPageRoutes);
  const noLayoutRoutesKeys = Object.keys(noLayoutRoutes);
  //   const userRouteKeys = Object.keys(userRoutes);
  return (
    <Suspense fallback={<PreloaderComponent />}>
      <Routes>
        <Route element={<AuthLayout />}>
          {routeKeys.map((routeKey, i) => {
            const mainRoute = mainRoutes[routeKey] as MainRoute | undefined;

            if (!mainRoute) {
              return null;
            }

            return (
              <Route
                key={i}
                path={`${mainRoute.path}${
                  mainRoute.routes && Object.keys(mainRoute.routes).length > 0
                    ? "/*"
                    : ""
                }`}
                element={mainRoute.element}
              >
                {mainRoute.routes &&
                  Object.keys(mainRoute.routes).map(
                    (nestedRouteKey, nestedIndex) => {
                      const nestedRoute = mainRoute.routes![nestedRouteKey] as
                        | NestedRoute
                        | undefined;

                      if (!nestedRoute) {
                        return null;
                      }

                      return (
                        <Route
                          key={nestedIndex}
                          path={nestedRoute.path}
                          element={nestedRoute.element}
                        />
                      );
                    }
                  )}
              </Route>
            );
          })}
        </Route>
        <Route element={<SignInLayout />}>
          {loginRouteKeys.map((routeKey, i) => {
            const loginRoute = loginRoutes[routeKey];

            if (!loginRoute) {
              return null;
            }
            return (
              <Route
                key={i}
                path={loginRoute.path}
                element={loginRoute.element}
              />
            );
          })}
        </Route>
        {/* <Route element={<SignUpLayout />}>
          {signUpRouteKeys.map((routeKey, i) => {
            const signupRoute = signUpRoutes[routeKey];

            if (!signupRoute) {
              return null;
            }
            return (
              <Route  
                key={i}
                path={signupRoute.path}
                element={signupRoute.element}
              />
            );
          })} 
        </Route> */}
        <Route element={<HomePageLayout />}>
          {homePageRoutesKeys.map((routeKey, i) => {
            const homePageRoute = homePageRoutes[routeKey];

            if (!homePageRoute) {
              return null;
            }

            return (
              <Route
                key={i}
                path={homePageRoute.path}
                element={homePageRoute.element}
              />
            );
          })}
        </Route>
        <Route>
          {noLayoutRoutesKeys.map((routeKey, i) => {
            const noLayoutRoute = noLayoutRoutes[routeKey];

            if (!noLayoutRoute) {
              return null;
            }

            return (
              <Route
                key={i}
                path={noLayoutRoute.path}
                element={noLayoutRoute.element}
              />
            );
          })}
        </Route>
        <Route element={<ContentPageLayout />}>
          {contentPageRoutesKeys.map((routeKey, i) => {
            const contentPageRoute = contentPageRoutes[routeKey];

            if (!contentPageRoute) {
              return null;
            }

            return (
              <Route
                key={i}
                path={contentPageRoute.path}
                element={contentPageRoute.element}
              />
            );
          })}
        </Route>
        {/* <Route element={<FullScreenLayout />}>
          {userRouteKeys.map((routeKey, i) => {
            const userRoute = userRoutes[routeKey];

            if (!userRoute) {
              return null;
            }
            return (
              <Route
                key={i}
                path={userRoute.path}
                element={userRoute.element}
              />
            );
          })}
        </Route> */}
        <Route path="*" element={<Error404 />} />
      </Routes>
    </Suspense>
  );
}
