// actions.ts
import api from "config/api";

import {
  getDeviceId,
  getUserInfo,
  removeUserData,
  storeUserInfo,
} from "helpers/StorageHelper";
import { t } from "i18next";
import store from "store";
import {
  errorMessage,
  infoMessage,
  successMessage,
} from "helpers/ToastNotificationHelper";
import { manageAPICall } from "./CommonCrudAction";
import { userInfoSelector } from "components/selectors/authSelectors";
export const handleLoginAPICall = async (data: any) => {
  const deviceId = getDeviceId();
  const apiPayload = {
    device_id: deviceId,
    fcm_token: "NA",
    device_name: navigator.userAgent,
    device_type: "W",
    device_model: "WEB",
    app_code: "1.0.0",
    device_os_version: "11",
    ...data,
  };
  const response = await manageAPICall(
    api.login.url,
    api.login.method,
    apiPayload
  );
  if (response.status) {
    const userData = response.data;
    storeUserInfo(userData);
  }
  return response;
};
export const loginUser = async (data: object, callback: any) => {
  manageAPICall(api.login.url, api.login.method, data, callback);
};
export const getUserProfile = async (data: object, callback: any) => {
  manageAPICall(api.getUserProfile.url, api.getUserProfile.method, data, callback);
};
export const socialLogin = async (data: object, callback: any) => {
  manageAPICall(api.socialLogin.url, api.socialLogin.method, data, callback);
};
export const checkAccountAvailability = async (data: object, callback: any) => {
  manageAPICall(
    api.checkAccountAvailability.url,
    api.checkAccountAvailability.method,
    data,
    callback
  );
};
export const logoutUser = async (callback: any) => {
  // var userInfo = userInfoSelector(store.getState());
  // var deviceId = userInfo?.device?.device_id;
  // if (deviceId) {
  // var data = {
  //   device_id: deviceId,
  // };
  manageAPICall(
    api.logout.url,
    api.logout.method,
    {},
    (status: boolean, response: any) => {
      if (response) {
        if (response.status) {
          successMessage(t("logout_success_msg"));
          removeUserData();
          store.dispatch({
            type: "UPDATE_USER_DETAILS",
            payload: null,
          });
          store.dispatch({
            type: "UPDATE_PROFILE_DATA",
            payload: null,
          });
          store.dispatch({
            type: "UPDATE_USER_SETTINGS",
            payload: null,
          });
          callback();
        } else {
          errorMessage(response.message);
        }
      }
    }
  );
};
export const storeUpdateUserState = (userInfo: any) => {
  store.dispatch({
    type: "UPDATE_USER_DETAILS",
    payload: userInfo,
  });
  if (userInfo?.user) {
    store.dispatch({
      type: "UPDATE_PROFILE_DATA",
      payload: userInfo.user,
    });
  }
  if (userInfo?.userSettings) {
    store.dispatch({
      type: "UPDATE_USER_SETTINGS",
      payload: userInfo.userSettings,
    });
  }
};
export const registerUser = async (data: object, callback: any) => {
  manageAPICall(api.registration.url, api.registration.method, data, callback);
};
export const updateProfile = async (data: object, callback: any) => {
  manageAPICall(
    api.updateProfile.url,
    api.updateProfile.method,
    data,
    callback
  );
};
export const addPublicProfile = async (data: object, callback: any) => {
  manageAPICall(
    api.addPublicProfile.url,
    api.addPublicProfile.method,
    data,
    callback
  );
};
export const editPublicProfile = async (data: object, callback: any) => {
  manageAPICall(
    api.editPublicProfile.url,
    api.editPublicProfile.method,
    data,
    callback
  );
};
export const getProfile = async (data: object, callback: any) => {
  manageAPICall(api.getProfile.url, api.getProfile.method, data, callback);
};
export const verifyOtp = async (data: object, callback: any) => {
  manageAPICall(api.verifyOtp.url, api.verifyOtp.method, data, callback);
};
export const resendOtp = async (data: object, callback: any) => {
  manageAPICall(api.resendOtp.url, api.resendOtp.method, data, callback);
};
export const forgotPassword = async (data: object, callback: any) => {
  manageAPICall(
    api.forgotPassword.url,
    api.forgotPassword.method,
    data,
    callback
  );
};
export const resetPassword = async (data: object, callback: any) => {
  manageAPICall(
    api.resetPassword.url,
    api.resetPassword.method,
    data,
    callback
  );
};
export const subscriptionPlanList = async (data: object, callback: any) => {
  manageAPICall(
    api.subscriptionPlanList.url,
    api.subscriptionPlanList.method,
    data,
    callback
  );
};

export const refreshToken = async (callback: any) => {
  const userInfo = userInfoSelector(store.getState());
  const data = {
    refresh_token: userInfo.refresh_token,
  };
  manageAPICall(
    api.refreshToken.url,
    api.refreshToken.method,
    data,
    (status: boolean, response: any) => {
      // console.log(response);
      if (response) {
        if (status) {
          const userInfo = getUserInfo();

          if (typeof userInfo === "string") {
            // Handle the case where userInfo is a string (if needed)
            console.error("Unexpected type: userInfo is a string.");
          } else {
            // Handle the case where userInfo is an object
            const obj: { token?: string } = userInfo || {};

            if (obj.token) {
              obj.token = response.data?.token;
            }

            storeUserInfo(obj);
          }
          callback();
        } else {
          console.log("error");
        }
      }
    }
  );
};
export const handleStartTakingActionMsg = () => {
  infoMessage(t("will_start_taking_action_soon_msg"), "no_title");
};
export const handlePublishRecipeFlowActionMsg = () => {
  infoMessage(t("publish_recipe_unnder_development"), "no_title");
};

export const handleRecipeDetailActionMsg = () => {
  infoMessage(t("publish_recipe_unnder_development"), "no_title");
};
export const launchCaptureEmail = async (data: object, callback: any) => {
  manageAPICall(
    api.launchCaptureEmail.url,
    api.launchCaptureEmail.method,
    data,
    callback
  );
};
