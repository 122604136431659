import { ReactElement, lazy } from "react";

interface Route {
  path: string;
  element: ReactElement;
  routes?: { [key: string]: Route };
}
const HomePage = lazy(() => import("Pages/Home"));
const LaunchingSoonPage = lazy(
  () => import("Pages/AllFrontPages/LauchingSoon")
);
const FacebookCallbackPage = lazy(
  () => import("Pages/SocialMedia/FacebookAuthCallback")
);
const AppleCallbackPage = lazy(
  () => import("Pages/SocialMedia/AppleAuthCallback")
);
const GoogleCallbackPage = lazy(
  () => import("Pages/SocialMedia/GoogleAuthCallback")
);
const FrontendDemoPage = lazy(
  () => import("Pages/AllFrontendDemoFiles/FrondendDemo")
);
const CommunityPage = lazy(() => import("Pages/Community"));
const RecipeDataByMealType = lazy(
  () => import("Pages/Recipe/RecipeDataByMealType")
);
const CommunityRecipeListPage = lazy(() => import("Pages/CommunityRecipe"));
const UserRecipeDetailPage = lazy(
  () => import("Pages/Recipe/UserRecipeDetailPage")
);
const Dashboard = lazy(() => import("Pages/Dashboard"));
const LoginPage = lazy(() => import("Pages/Login"));
const SignUpPage = lazy(() => import("Pages/Login/SignUp"));
const VerifyOtpPage = lazy(() => import("Pages/Login/VerifyOtp"));
const SubscriptionPlanPage = lazy(() => import("Pages/Login/SubscriptionPlan"));
const ForgotPasswordPage = lazy(() => import("Pages/Login/ForgotPassword"));
const ResetPasswordPage = lazy(() => import("Pages/Login/ResetPassword"));
const RecipePage = lazy(() => import("Pages/Recipe"));
const RecipeDetailPage = lazy(() => import("Pages/Recipe/TestingPage"));
const RecipeVaultPage = lazy(() => import("Pages/RecipeVault/index"));
const PublishedRecipePage = lazy(
  () => import("Pages/RecipeVault/PublishedRecipe")
);
const SavedRecipePage = lazy(() => import("Pages/RecipeVault/SavedRecipe"));
const ModalPopUpPage = lazy(() => import("Pages/Recipe/ModalPopup"));
const LargePopUpPage = lazy(
  () => import("Pages/AllFrontendDemoFiles/LargeModalPopUp")
);
//Analytics
const AnalyticsDesign = lazy(() => import("Pages/Analytics/Design"));
const AnalyticsPage = lazy(() => import("Pages/Analytics"));
const AnalyticDemographicPage = lazy(
  () => import("Pages/Analytics/AnalyticDemographic")
);
const AnalyticConsumptionTrendPage = lazy(
  () => import("Pages/Analytics/AnalyticConsumptionTrend")
);
const AnalyticConsumerBehaviorPage = lazy(
  () => import("Pages/Analytics/AnalyticConsumerBehavior")
);
// performance
const Performance = lazy(
  () => import("Pages/AllFrontendDemoFiles/Performance")
);
const PerformancePage = lazy(() => import("Pages/Performance"));
const PerformanceOverviewPage = lazy(
  () => import("Pages/Performance/PerformanceOverview")
);
const PerformanceViewPage = lazy(
  () => import("Pages/Performance/PerformanceViews")
);
const PerformanceSavePage = lazy(
  () => import("Pages/Performance/PerformanceSaves")
);
const PerformanceEngagementPage = lazy(
  () => import("Pages/Performance/PerformanceEngagement")
);
const PerformanceEngagementRecipeWisePage = lazy(
  () => import("Pages/Performance/PerformanceEngagementRecipeWise")
);
// recipe feedback
const TestingRecipeListPage = lazy(() => import("Pages/RecipeFeedback"));
const TestingRecipeDetailPage = lazy(
  () => import("Pages/RecipeFeedback/TestingRecipeDetail")
);
//...............
// const Analytic1 = lazy(() => import("Pages/AllFrontendDemoFiles/Analytic1"));
const Analytic2 = lazy(() => import("Pages/AllFrontendDemoFiles/Analytic2"));
const Analytic3 = lazy(() => import("Pages/AllFrontendDemoFiles/Analytic3"));
const Analytic4 = lazy(() => import("Pages/AllFrontendDemoFiles/Analytic4"));
const AllDemoFilePage = lazy(
  () => import("Pages/Recipe/FormAccordianRecipeBoxAndList")
);
const AboutUsPage = lazy(() => import("Pages/AllFrontPages/AboutUs"));
const AboutUsLearnMorePage = lazy(() => import("Pages/AllFrontPages/OikosAi"));
const SupportPage = lazy(() => import("Pages/AllFrontPages/Support"));
const BecomeAPaidTester = lazy(
  () => import("Pages/AllFrontPages/BecomeAPaidTester")
);
const JoinInfluencerTeam = lazy(
  () => import("Pages/AllFrontPages/JoinInfluencerTeam")
);
export const homePageRoutes: { [key: string]: Route } = {
  homePage: { path: "/", element: <HomePage /> },
  FrontendDemoPage: {
    path: "/frontend-demo-page",
    element: <FrontendDemoPage />,
  },
  CommunityPage: {
    path: "/community",
    element: <CommunityPage />,
  },
  RecipeDataByMealType: {
    path: "/recipe-data-by-meal-type/:type",
    element: <RecipeDataByMealType />,
  },
  CommunityDetailPage: {
    path: "/user-recipe-detail/:id",
    element: <UserRecipeDetailPage />,
  },
  CommunityRecipeListPage: {
    path: "/community/recipe/",
    element: <CommunityRecipeListPage />,
  },
  // AboutUsPage: { path: "/about-us", element: <AboutUsPage /> },
  // AboutUsLearnMorePage: {
  //   path: "/learn-more",
  //   element: <AboutUsLearnMorePage />,
  // },
  // SupportPage: { path: "/support", element: <SupportPage /> },
  // BecomeAPaidTester: {
  //   path: "/become-a-paid-tester",
  //   element: <BecomeAPaidTester />,
  // },
  // JoinInfluencerTeam: {
  //   path: "/join-influencer-team",
  //   element: <JoinInfluencerTeam />,
  // },
};
export const noLayoutRoutes: { [key: string]: Route } = {
  lauchingPage: { path: "/lauching-soon", element: <LaunchingSoonPage /> },
  facebookCallbackPage: {
    path: "/facebook-auth/callback",
    element: <FacebookCallbackPage />,
  },
  googleCallbackPage: {
    path: "/google-auth/callback",
    element: <GoogleCallbackPage />,
  },
  appleCallbackPage: {
    path: "/apple-auth/callback",
    element: <AppleCallbackPage />,
  },
};
export const contentPageRoutes: { [key: string]: Route } = {
  AboutUsPage: { path: "/about-us", element: <AboutUsPage /> },
  AboutUsLearnMorePage: {
    path: "/learn-more",
    element: <AboutUsLearnMorePage />,
  },
  SupportPage: { path: "/support", element: <SupportPage /> },
  BecomeAPaidTester: {
    path: "/become-a-paid-tester",
    element: <BecomeAPaidTester />,
  },
  JoinInfluencerTeam: {
    path: "/join-influencer-team",
    element: <JoinInfluencerTeam />,
  },
};
export const performanceRoutes = {
  overview: { path: "overview", element: <PerformanceOverviewPage /> },
  views: { path: "views", element: <PerformanceViewPage /> },
  saves: { path: "saves", element: <PerformanceSavePage /> },
  engagement: { path: "engagement", element: <PerformanceEngagementPage /> },
  engagementRecipeWise: {
    path: "engagement/recipe/:id",
    element: <PerformanceEngagementRecipeWisePage />,
  },
};
export const analyticsRoutes = {
  demographics: { path: "demographics", element: <AnalyticDemographicPage /> },
  consumptionTrends: {
    path: "consumption-trends",
    element: <AnalyticConsumptionTrendPage />,
  },
  consumerBehavior: {
    path: "consumer-behavior",
    element: <AnalyticConsumerBehaviorPage />,
  },
};
export const mainRoutes: { [key: string]: Route } = {
  dashboard: { path: "/dashboard", element: <Dashboard /> },
  recipe: { path: "/recipe/:id", element: <RecipePage /> },
  recipeDetailPage: {
    path: "/recipe-details/:id",
    element: <RecipeDetailPage />,
  },

  recipeVault: { path: "/recipe-vault", element: <RecipeVaultPage /> },
  publishedRecipe: {
    path: "/published-recipe",
    element: <PublishedRecipePage />,
  },
  savedRecipePage: {
    path: "/saved-recipe",
    element: <SavedRecipePage />,
  },
  testingRecipe: {
    path: "/testing-recipe",
    element: <TestingRecipeListPage />,
  },
  testingRecipeDetail: {
    path: "/testing-recipe/details/:id",
    element: <TestingRecipeDetailPage />,
  },
  modalPopUpPage: { path: "/modal-pop-up", element: <ModalPopUpPage /> },
  LargePopUpPage: { path: "/large-pop-up", element: <LargePopUpPage /> },
  analyticsDesign: { path: "/analytics-design", element: <AnalyticsDesign /> },
  performancedesign: { path: "/performance-design", element: <Performance /> },
  performance: {
    path: "/performance",
    element: <PerformancePage />,
    routes: performanceRoutes,
  },
  analytics: {
    path: "/analytics",
    element: <AnalyticsPage />,
    routes: analyticsRoutes,
  },
  Analytic2: { path: "/analytic-2", element: <Analytic2 /> },
  Analytic3: { path: "/analytic-3", element: <Analytic3 /> },
  Analytic4: { path: "/analytic-4", element: <Analytic4 /> },
  allDemoPage: { path: "/all-demo", element: <AllDemoFilePage /> },
};
export const loginRoutes: { [key: string]: Route } = {
  login: { path: "/login", element: <LoginPage /> },
  signUp: { path: "/sign-up", element: <SignUpPage /> },
  verifyOtp: { path: "/verify-otp", element: <VerifyOtpPage /> },
  resetPassword: { path: "/reset-password", element: <ResetPasswordPage /> },
  subscriptionPlan: {
    path: "/subscription-plan",
    element: <SubscriptionPlanPage />,
  },
  forgotPassword: {
    path: "/forgot-password-page",
    element: <ForgotPasswordPage />,
  },
};
export const getUserHomePageRoute = (): string => {
  return mainRoutes.dashboard.path;
};
