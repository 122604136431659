import axios from "axios";
import { API_URL } from "config/app.config";
import { getUserHomePageRoute, loginRoutes } from "config/routing";
import { removeLocalStorage, removeUserData } from "helpers/StorageHelper";
import { refreshToken } from "store/actions/AuthAction";
// import { refreshToken } from 'store/actions/AuthAction';
const instance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-type": "application/json",
  },
});
/**
 * Catch the unAuthorized Request
 */
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      if (
        error.response.data.message == "token_expired" ||
        error.response.data.message == "Unauthorized Access"
      ) {
        // ('Login Session is expired');
        setTimeout(() => {
          removeUserData();
          removeLocalStorage("userInfo");
          redirectHome(true);
        }, 1000);
      } else {
        // call an action
        refreshToken(function () {
          redirectHome(false);
        });
      }
      return false;
    } else {
      let message = error?.response?.message || "Something went wrong!";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message = error.response.data.message;
      }
      return {
        status: false,
        hasError: true,
        message: message,
      };
    }
  }
);
function redirectHome(isLoggedout = false) {
  if (isLoggedout) {
    window.location.assign(loginRoutes.login.path);
  } else {
    window.location.assign(getUserHomePageRoute());
  }
}
export default instance;
