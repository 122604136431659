export const userInfoSelector = (state: any) => state.auth.userInfo;
export const userProfileSelector = (state: any) => state.auth.profileData;
export const userSettingSelector = (state: any) => state.auth.userSettings;
export const hasUserSubScribedSelector = (state: any) => {
  let hasSubscribed = false;
  const userSettings = userSettingSelector(state);
  if (userSettings) {
    hasSubscribed = userSettings.subscription_status;
  }
  return hasSubscribed;
};
export const dateDisplayUserFormatSelector = (state: any) => {
  return "MMMM DD, YYYY";
};
export const dateTimeWithSecondDisplayFormatSelector = (state: any) => {
  return "MM/DD/YYYY HH:mm:ss";
};
export const datePickerDispalyDateFormatSelector = (state: any) => "MM/DD/YYYY";
export const datePickerDateFormatSelector = (state: any) => "MM/dd/yyyy";
