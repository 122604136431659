import Footer from "components/_common/Footer";
import Header from "components/_common/Header";
import { Outlet } from "react-router-dom";

function SignInLayout() {
  return (
    <>
      <Header showHeaderMenu={true} />
      <div className="">
        <Outlet />
      </div>
      <Footer />
    </>
  );
}

export default SignInLayout;
