import {
  decryptObject,
  encryptObject,
  generateRandomNumber,
} from "./CommonHelper";

export const getDeviceId = (): string => {
  let deviceId = getLocalStorage("deviceId");
  if (deviceId) {
    return deviceId;
  } else {
    deviceId = generateRandomNumber().toString();
    storeLocalStorage("deviceId", deviceId);
    return deviceId;
  }
};
export const storeLocalStorage = (key: string, value: string): void => {
  localStorage.setItem(key, value);
};

export const getLocalStorage = (key: string): string | null => {
  return localStorage.getItem(key);
};
export const removeLocalStorage = (key: string): void => {
  localStorage.removeItem(key);
};
export const getUserAuthToken = (): string => {
  const userInfo = getUserInfo();
  let authorization = "";
  if (userInfo) {
    authorization = userInfo.token;
  }
  return authorization;
};
export const getUserInfo = (): any => {
  const userInfo = localStorage.getItem("userInfo");
  if (userInfo) {
    return decryptObject(userInfo);
  }
  return null;
};

export const storeUserInfo = (obj: any): void => {
  const encryptedObj = encryptObject(obj);
  storeLocalStorage("userInfo", encryptedObj);
};
export const removeUserData = (): void => {
  removeLocalStorage("userInfo");
  removeLocalStorage("deviceId");
};
