import { error500 } from "components/_common/Icons";
import Button from "components/base/Button";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const Error500 = () => {
  return (
    <div>
      <div className="px-3">
        <Row className="min-vh-100 flex-center p-5">
          <Col xs={12} xl={10} xxl={8}>
            <Row className="justify-content-center  g-5">
              <Col xs={12} lg={6} className="text-center text-lg-start">
                <img
                  src={error500}
                  className="img-fluid mb-6 w-50 w-lg-75 d-dark-none"
                  alt=""
                />
                <h2 className="text-800 fw-bolder mb-3">Unknow error!</h2>
                <p className="text-900 mb-5">
                  {/* But relax! Our cat is here to play you some music. */}
                  An error occurred. Please reload page and try again
                </p>
                <Button
                  variant="primary"
                  size="lg"
                  onClick={() => {
                    document.location.reload();
                  }}
                >
                  Reload
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Error500;
