import React from "react";
import LaunchingSoonSection from "./LaunchingSoonSection";
import LaunchingSoonSectionV2 from "./LaunchingSoonSectionV2";

export default function LaunchingSoon() {
  return (
    <>
      {/*  <LaunchingSoonSection /> */}
      <LaunchingSoonSectionV2 />
    </>
  );
}
