import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { t } from "i18next";
import {
  handleStartTakingActionMsg,
  launchCaptureEmail,
} from "store/actions/AuthAction";
import { errorMessage, successMessage } from "helpers/ToastNotificationHelper";
export default function PreRegisterForm() {
  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    preRegister_email: Yup.string()
      .required(t("required_error_msg", { field_name: t("email") }))
      .email(t("invalid_error_msg", { field_name: t("email") })),
  });
  const defaultValue = {
    preRegister_email: "",
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValue,
  });
  const onSubmitCaptureEmail = (data: any) => {
    if (!loading) {
      setLoading(true);
      const payload = {
        type: "preRegister",
        email: data.preRegister_email,
      };
      console.log(payload);

      launchCaptureEmail(payload, (status: boolean, response: any) => {
        setLoading(false);
        if (status) {
          console.log(response);
          successMessage(t("capture_email_success_msg"));
          reset(defaultValue);
        } else {
          errorMessage(response.message);
        }
      });
    }
  };
  return (
    <form
      className="pioneer-form-section d-flex my-2"
      onSubmit={handleSubmit(onSubmitCaptureEmail)}
    >
      <div className="col-12 col-sm-8 col-md-10 col-lg-8 col-xl-7 col-xxl-6 m-0 m-auto">
        <div className="input-group">
          <input
            type="email"
            className="form-control"
            placeholder="I want to be a Pioneer"
            {...register("preRegister_email")}
          />
          <div className="input-group-append">
            <button
              className="btn btn-create-primary w-100 rounded-end-1 rounded-0"
              type="submit"
              /* onClick={() => handleStartTakingActionMsg()} */
            >
              Submit
            </button>
          </div>
        </div>
        {errors.preRegister_email && (
          <div className="d-block invalid-feedback error">
            {errors.preRegister_email?.message}
          </div>
        )}
      </div>
    </form>
  );
}
