import store from "store";
import APIService from "services/api.service";
import api from "config/api";

// Define types for API responses and parameters
type APIResponse<T> = {
  status: number;
  data: T;
};

type APIMethod = "GET" | "POST" | "PATCH" | "DELETE" | "POSTFORMDATA";

// Type for the callback function
type Callback<T> = (status: number, response: APIResponse<T>) => void;

type Params = Record<string, any>; // Adjust as needed based on actual parameters

// Function to get list data

// Function to manage API calls
export const manageAPICall = async (
    apiurl: string,
    method: string,
    data: object,
    callback?: any
  ) => {
    let response;
    if (method == 'GET') {
      response = await APIService.getData(apiurl, data);
    } else if (method == 'POST') {
      response = await APIService.submitData(apiurl, data);
    } else if (method == 'PATCH') {
      response = await APIService.submitPatchData(apiurl, data);
    } else if (method == 'DELETE') {
      response = await APIService.deleteData(apiurl, data);
    } else if (method == 'POSTFORMDATA') {
      response = await APIService.submitFormData(apiurl, data);
    }
    if (response) {
      if (callback) {
        callback(response.status, response);
      } else {
        return response;
      }
    }
  };
