import {
  Facebook,
  Twitter,
  Linkdin,
  Instagram,
  AppWhiteLogo,
  AppLogo,
  AppWhiteLucLogo,
} from "components/_common/Icons";
import { Preloader } from "components/_common/Preloader";
import { LAUNCH_DATE, SHOW_LAUNCH_TIME } from "config/app.config";
import { errorMessage, successMessage } from "helpers/ToastNotificationHelper";
import React, { useEffect, useState } from "react";

import { t } from "i18next";
import PreRegisterForm from "./PreRegisterForm";
import NotifyForm from "./NotifyForm";
import { getAzureFilePublicUrl } from "helpers/AzureHelper";
function LaunchingSoonSectionV2() {
  const [timeLeft, setTimeLeft] = useState({
    days: "00",
    hours: "00",
    minutes: "00",
    seconds: "00",
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (LAUNCH_DATE) {
      const countdown = setInterval(() => {
        try {
          const now = new Date().getTime();
          const launchDate = new Date(LAUNCH_DATE).getTime();
          //   console.log(launchDate);

          const distance = launchDate - now;

          if (distance < 0) {
            setLoading(false);
            clearInterval(countdown);
            return;
          }

          const days = Math.floor(distance / (1000 * 60 * 60 * 24))
            .toString()
            .padStart(2, "0");
          const hours = Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          )
            .toString()
            .padStart(2, "0");
          const minutes = Math.floor(
            (distance % (1000 * 60 * 60)) / (1000 * 60)
          )
            .toString()
            .padStart(2, "0");
          const seconds = Math.floor((distance % (1000 * 60)) / 1000)
            .toString()
            .padStart(2, "0");

          setTimeLeft({ days, hours, minutes, seconds });
          setTimeout(() => {
            setLoading(false);
          }, 10);
        } catch (e: any) {
          setLoading(false);
        }
      }, 1000);

      return () => clearInterval(countdown);
    }
  }, []);
  if (!loading) {
    return (
      <>
        {/* start header title */}
        {/* <div className="row border-1 border-black border-bottom gx-0 mb-3 mx-1">
          <div className="col-12 col-md-12 gx-2 p-0">
            <h2>Become a Paid Tester</h2>
          </div>
        </div>  */}
        {/* end header title */}
        <video autoPlay muted loop playsInline className="launch-soon video-bg">
          <source
            src={getAzureFilePublicUrl(
              "public/upload/general/wood-oven-fire.mp4"
            )}
            type="video/mp4"
          />
          {/* Replace with the actual video file path */}
          Your browser does not support the video tag.
        </video>
        {/* Content Overlay on the Left */}
        <div className="container-fluid launch-soon">
          <div className="row">
            {/* Left Content Section */}
            <div className="col-12 col-md-4 content-overlay">
              <div className="logo">
                <img src={AppWhiteLucLogo} alt="Logo" />
                {/* Replace with your logo path */}
              </div>
              <h3 className="mb-2 logo-title">LUC</h3>
              <p className="logo-subtitle-1">Le Unum Culinae</p>
              <p className="logo-subtitle-2">(The Unity of Kitchens)</p>
              <h6 className="mt-5">Launching Soon</h6>
              {LAUNCH_DATE && SHOW_LAUNCH_TIME == "true" && (
                <div className="countdown d-flex justify-content-center">
                  <div className="time-unit mx-3">
                    <div className="time-number">{timeLeft.days}</div>
                    <div className="time-label">days</div>
                  </div>
                  <div className="time-unit mx-3">
                    <div className="time-number">{timeLeft.hours}</div>
                    <div className="time-label">hours</div>
                  </div>
                  <div className="time-unit mx-3">
                    <div className="time-number">{timeLeft.minutes}</div>
                    <div className="time-label">minutes</div>
                  </div>
                  <div className="time-unit mx-3">
                    <div className="time-number">{timeLeft.seconds}</div>
                    <div className="time-label">seconds</div>
                  </div>
                </div>
              )}
              <p className="mt-4">Dinner is not quite ready</p>
              <NotifyForm />
              {/* <form className="notify-form d-flex my-2">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Notify Me"
                />
                <button className="btn btn-primary ms-2">Submit</button>
              </form> */}
              <p className="mt-4">
                Join our Pioneers Program
                <br />
                <small>
                  Early access, benefits, discounts, awards, &amp; more!
                </small>
              </p>
              {/* <form className="pioneer-form d-flex my-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="I want to be a Pioneer"
                />
                <button className="btn btn-primary ms-2">Submit</button>
              </form> */}
              <PreRegisterForm />
              {/*  */}
            </div>
            {/* Right Section (Empty for Video Background) */}
            {/* <div className="col-md-8" /> */}
          </div>
        </div>
      </>
    );
  } else {
    return <Preloader loading={true} />;
  }
}

export default LaunchingSoonSectionV2;
