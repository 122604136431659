import React, { PropsWithChildren, FC } from "react";
import { connect } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { getUserAuthToken } from "helpers/StorageHelper";
import { loginRoutes } from "config/routing";
import { RootState } from "store";
import { userProfileSelector } from "components/selectors/authSelectors";

interface ProtectedRouteProps {
  userProfileInfo?: ReturnType<typeof userProfileSelector>;
}

const ProtectedRoute: FC<PropsWithChildren<ProtectedRouteProps>> = ({
  children,
}) => {
  const location = useLocation();
  const user = getUserAuthToken();

  if (!user) {
    // not logged in so redirect to login page with the return url
    return <Navigate to={loginRoutes.login.path} replace />;
  }

  return <>{children}</>;
};

const mapStateToProps = (state: RootState) => ({
  userProfileInfo: userProfileSelector(state),
});

export default connect(mapStateToProps)(ProtectedRoute);
