import { errorMessage } from "helpers/ToastNotificationHelper";
import qs from "qs";
import store from "store";
import http from "./axiosConfig";
import { getUserAuthToken } from "helpers/StorageHelper";
class APIService {
  addHeaders(token: string): void {
    http.defaults.headers.common["Authorization"] = "Bearer " + token;
    http.defaults.headers.common["X-Language-Id"] = "1";
    http.defaults.headers.common["ngrok-skip-browser-warning"] = "69420";
  }

  async getData(url: string, data: object): Promise<any> {
    try {
      const token = getUserAuthToken();
      if (token) {
        this.addHeaders(token);
      }

      const res: any = await http.get(
        `${url}${data ? "?" + qs.stringify(data) : ""}`
      );
      // if (res) {
      //   return res.data;
      // }
      if (res.data) {
        return res.data;
      } else {
        return res;
      }
    } catch (err: any) {
      console.log(err);
      if (err?.response?.status !== 401) {
        errorMessage("Something went wrong!");
        return Promise.reject(err);
      }
    }
  }

  async submitData(url: string, data: object): Promise<any> {
    try {
      const token = getUserAuthToken();
      if (token) {
        this.addHeaders(token);
      }

      const res: any = await http.post(url, data);
      // return res.data;
      if (res.data) {
        return res.data;
      } else {
        return res;
      }
    } catch (err: any) {
      console.log(err);
      if (err?.response?.status !== 401) {
        return {
          status: false,
          message: "Something went wrong!",
        };
      }
    }
  }

  async submitFormData(url: string, data: object): Promise<any> {
    try {
      const token = getUserAuthToken();
      if (token) {
        this.addHeaders(token);
      }

      const res: any = await http.post(url, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      // return res.data;
      if (res.data) {
        return res.data;
      } else {
        return res;
      }
    } catch (err: any) {
      console.log(err);
      if (err?.response?.status !== 401) {
        return {
          status: false,
          message: "Something went wrong!",
        };
      }
    }
  }

  async submitPatchData(url: string, data: object): Promise<any> {
    try {
      const token = getUserAuthToken();
      if (token) {
        this.addHeaders(token);
      }

      const res = await http.patch(url, data);
      return res.data;
    } catch (err: any) {
      console.log(err);
      if (err?.response?.status !== 401) {
        return {
          status: false,
          message: "Something went wrong!",
        };
      }
    }
  }

  async deleteData(url: string, data: object): Promise<any> {
    try {
      const token = getUserAuthToken();
      if (token) {
        this.addHeaders(token);
      }

      const res = await http.delete(url, { data });
      return res.data;
    } catch (err: any) {
      console.log(err);
      if (err?.response?.status !== 401) {
        return {
          status: false,
          message: "Something went wrong!",
        };
      }
    }
  }

  async getPublicTokenData(
    url: string,
    data: object,
    token = ""
  ): Promise<any> {
    try {
      if (token) {
        this.addHeaders(token);
      }

      const res = await http.get(url, data);
      return res.data;
    } catch (err: any) {
      console.log(err);
      if (err?.response?.status !== 401) {
        return {
          status: false,
          message: "Something went wrong!",
        };
      }
    }
  }

  async submitPublicTokenData(
    url: string,
    data: object,
    token = ""
  ): Promise<any> {
    try {
      if (token) {
        this.addHeaders(token);
      }

      const res = await http.post(url, data);
      return res.data;
    } catch (err: any) {
      console.log(err);
      if (err?.response?.status !== 401) {
        return {
          status: false,
          message: "Something went wrong!",
        };
      }
    }
  }
}

export default new APIService();
