import api from "config/api";
import { manageAPICall } from "./CommonCrudAction";

export const createRecipe = async (data: object, callback: any) => {
  manageAPICall(api.createRecipe.url, api.createRecipe.method, data, callback);
};
export const addComment = async (data: object, callback: any) => {
  manageAPICall(api.addComment.url, api.addComment.method, data, callback);
};
export const updateRecipe = async (data: object, callback: any) => {
  manageAPICall(api.updateRecipe.url, api.updateRecipe.method, data, callback);
};
export const support = async (data: object, callback: any) => {
  manageAPICall(api.support.url, api.support.method, data, callback);
};
export const publishRecipe = async (data: object, callback: any) => {
  manageAPICall(
    api.publishRecipe.url,
    api.publishRecipe.method,
    data,
    callback
  );
};
export const recipeTypeList = async (data: object, callback: any) => {
  manageAPICall(
    api.recipeTypeList.url,
    api.recipeTypeList.method,
    data,
    callback
  );
};
export const detailRecipe = async (data: object, callback: any) => {
  manageAPICall(api.detailRecipe.url, api.detailRecipe.method, data, callback);
};
export const userRecipe = async (data: object, callback: any) => {
  manageAPICall(api.userRecipe.url, api.userRecipe.method, data, callback);
};
export const getConsumptionTrends = async (data: object, callback: any) => {
  manageAPICall(api.getConsumptionTrends.url, api.getConsumptionTrends.method, data, callback);
};
export const testerList = async (data: object, callback: any) => {
  manageAPICall(api.testerList.url, api.testerList.method, data, callback);
};
export const getComment = async (data: object, callback: any) => {
  manageAPICall(api.getComment.url, api.getComment.method, data, callback);
};
export const getBanner = async (data: object, callback: any) => {
  manageAPICall(api.getBanner.url, api.getBanner.method, data, callback);
};
export const countryList = async (data: object, callback: any) => {
  manageAPICall(api.countryList.url, api.countryList.method, data, callback);
};
export const fetchPinCode = async (data: object, callback: any) => {
  manageAPICall(api.fetchPinCode.url, api.fetchPinCode.method, data, callback);
};
export const recipeList = async (data: object, callback: any) => {
  manageAPICall(api.recipeList.url, api.recipeList.method, data, callback);
};
export const likeUnlikeRecipe = async (data: object, callback: any) => {
  manageAPICall(
    api.likeUnlikeRecipe.url,
    api.likeUnlikeRecipe.method,
    data,
    callback
  );
};
export const saveUnsaveRecipe = async (data: object, callback: any) => {
  manageAPICall(
    api.saveUnsaveRecipe.url,
    api.saveUnsaveRecipe.method,
    data,
    callback
  );
};
export const getMostSavedRecipeList = async (data: object, callback: any) => {
  manageAPICall(
    api.mostSavedRecipeList.url,
    api.mostSavedRecipeList.method,
    data,
    callback
  );
};
export const savedRecipeList = async (data: object, callback: any) => {
  manageAPICall(
    api.savedRecipeList.url,
    api.savedRecipeList.method,
    data,
    callback
  );
};
export const duplicateRecipe = async (data: object, callback: any) => {
  manageAPICall(
    api.duplicateRecipe.url,
    api.duplicateRecipe.method,
    data,
    callback
  );
};
export const deleteRecipeHeader = async (data: object, callback: any) => {
  manageAPICall(
    api.deleteRecipeHeader.url,
    api.deleteRecipeHeader.method,
    data,
    callback
  );
};
export const deleteRecipeItems = async (data: object, callback: any) => {
  manageAPICall(
    api.deleteRecipeItems.url,
    api.deleteRecipeItems.method,
    data,
    callback
  );
};
export const reorderRecipeItems = async (data: object, callback: any) => {
  manageAPICall(
    api.reorderRecipeItems.url,
    api.reorderRecipeItems.method,
    data,
    callback
  );
};
export const createRecipeHeader = async (data: object, callback: any) => {
  manageAPICall(
    api.createRecipeHeader.url,
    api.createRecipeHeader.method,
    data,
    callback
  );
};
export const feedback = async (data: object, callback: any) => {
  manageAPICall(api.feedback.url, api.feedback.method, data, callback);
};
export const updateRecipeHeader = async (data: object, callback: any) => {
  manageAPICall(
    api.updateRecipeHeader.url,
    api.updateRecipeHeader.method,
    data,
    callback
  );
};
export const submitForTesting = async (data: object, callback: any) => {
  manageAPICall(
    api.submitForTesting.url,
    api.submitForTesting.method,
    data,
    callback
  );
};
export const aiAssistance = async (data: object, callback: any) => {
  manageAPICall(api.aiAssistance.url, api.aiAssistance.method, data, callback);
};
export const createRecipeIngredient = async (data: object, callback: any) => {
  manageAPICall(
    api.createRecipeIngredient.url,
    api.createRecipeIngredient.method,
    data,
    callback
  );
};

export const sendAiAssistanceMessage = async (data: object, callback: any) => {
  manageAPICall(
    api.sendAiAssistanceMessage.url,
    api.sendAiAssistanceMessage.method,
    data,
    callback
  );
};
export const getTesterObj = (element: any) => {
  const obj = {
    id: element.id,
    name: element.first_name + " " + element.last_name,
  };
  return obj;
};
export const getRecipeTypeObj = (element: any) => {
  const obj = {
    id: element.id,
    name: element.name,
  };
  return obj;
};
