import { closeIcon, PopupIcon } from "components/_common/Icons";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm, SubmitHandler, FieldValues } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import { mainRoutes as routes } from "config/routing";
import { Preloader } from "components/_common/Preloader";
import { errorMessage, successMessage } from "helpers/ToastNotificationHelper";
import { updateProfile } from "store/actions/AuthAction";
import { fetchPinCode } from "store/actions/RecipeAction";
import { useTranslation } from "react-i18next";

interface PersonalProfileModalProps {
  showModal: boolean;
  existingDetails: any;
  handleOnModalClose: () => void;
}
interface PersonalProfileValues {
  first_name?: string;
  last_name?: string;
  email_id?: string;
  username?: string;
  pincode?: string;
}
export default function PersonalProfileModal(props: PersonalProfileModalProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { handleOnModalClose, showModal, existingDetails } = props;
  const [loading, setLoading] = useState(false);
  const [timeoutId, setTimeoutId] = useState<any>(null);
  const [zipCodeVal, setZipCodeVal] = useState<string>("");
  const [updatedPincode, setUpdatedPincode] = useState<string>("");
  const [customErrorMessage, setCustomErrorMessage] = useState<string>("");
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required(
      t("required_error_msg", { field_name: t("first_name") })
    ),
    last_name: Yup.string().required(
      t("required_error_msg", { field_name: t("last_name") })
    ),
    email_id: Yup.string().required(
      t("required_error_msg", { field_name: t("testing_start_date") })
    ),
    username: Yup.string().required(
      t("required_error_msg", { field_name: t("username") })
    ),
    pincode: Yup.string().required(
      t("required_error_msg", { field_name: t("pincode") })
    ),
  });
  console.log("updatedPincode", updatedPincode);

  const {
    register,
    handleSubmit,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  useEffect(() => {
    if (existingDetails) {
      setValue("first_name", existingDetails.user.first_name);
      setValue("last_name", existingDetails.user.last_name);
      setValue("email_id", existingDetails.user.email);
      setValue("username", existingDetails.user.username);
      setValue("pincode", existingDetails.notification_settings.pincode.code);
    }
  }, []);
  const fetchLocationData = () => {
    const payload = {
      pincode: zipCodeVal,
      countryISOCode: existingDetails.notification_settings.country.code,
    };
    fetchPinCode(payload, (success: boolean, response: any) => {
      if (success && response?.status) {
        // Check for success and if response status is true
        if (response.data) {
          setUpdatedPincode(response.data.pincode.id);
          setCustomErrorMessage("");
        }
      } else {
        setCustomErrorMessage("Pincode is invalid");
      }
    });
  };
  const onSubmit: SubmitHandler<any> = async (data) => {
    const apiPayload = {
      ...data,
    };
    if (!loading) {
      apiPayload.pincode = updatedPincode;
      setLoading(true);
      updateProfile(apiPayload, (success: boolean, response: any) => {
        setLoading(false);
        if (success) {
          successMessage(t("update_successfully"));
          handleOnModalClose();
        } else {
          errorMessage(response.message);
        }
      });
    }
  };

  const handleZipCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setZipCodeVal(e.target.value);
    setValue("pincode", e.target.value);
  };
  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(() => {
      if (zipCodeVal.trim() !== "") {
        fetchLocationData();
      }
    }, 1000);
    setTimeoutId(newTimeoutId);
  }, [zipCodeVal]);

  return (
    <Modal
      show={showModal}
      onHide={handleOnModalClose}
      id="addEditModal"
      scrollable={false}
      size="lg"
      backdrop={false}
      centered
    >
      <Preloader loading={loading} />
      <div className="recipe-modal">
        <Modal.Header className="p-0 px-2 py-1">
          <div className="col-1">
            <img src={PopupIcon} style={{ width: "60%", minWidth: "80%" }} />
          </div>
          <div className="col-9 p-0 m-0">
            <h6>{t("personal_info")}</h6>
          </div>
          <div className="col-2 text-end">
            <span
              aria-hidden="true"
              onClick={handleOnModalClose}
              className="close cursor-pointer cursor-pointer px-3"
              data-dismiss="modal"
              aria-label="Close"
            >
              <img style={{ width: "15px" }} src={closeIcon} alt="close" />
            </span>
          </div>
        </Modal.Header>
        <Modal.Body className="bg-secondary-subtle">
          <div className="row m-0">
            <div className="col-12">
              <p>
                These details will be used for profiles associated with your
                email address. By filling out this information, you will receive
                a more personalized experience accross our website.
              </p>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row m-0 ">
              <h5 className="border-bottom border-black py-2 mt-2">
                My Basic Info
              </h5>
              <div className="col-12 my-2">
                <div className="form-group">
                  <label>Email address</label>
                  <input
                    type="email"
                    className={`form-control ${
                      errors.email_id ? "is-invalid" : ""
                    }`}
                    id="exampleInputEmail1"
                    {...register("email_id")}
                    aria-describedby="emailHelp"
                    disabled
                    placeholder="Enter email"
                  />
                  <div className="invalid-feedback error">
                    {errors.email_id?.message}
                  </div>
                </div>
              </div>

              <div className="col-6 my-2">
                <div className="form-group">
                  <label>First Name</label>
                  <input
                    type="text"
                    {...register("first_name")}
                    className={`form-control ${
                      errors.first_name ? "is-invalid" : ""
                    }`}
                    placeholder="First Name"
                  />
                  <div className="invalid-feedback error">
                    {errors.first_name?.message}
                  </div>
                </div>
              </div>

              <div className="col-6 my-2">
                <div className="form-group">
                  <label>Last Name</label>
                  <input
                    type="text"
                    className={`form-control ${
                      errors.last_name ? "is-invalid" : ""
                    }`}
                    {...register("last_name")}
                    placeholder="Last Name"
                  />
                  <div className="invalid-feedback error">
                    {errors.last_name?.message}
                  </div>
                </div>
              </div>

              <div className="col-6 my-2">
                <div className="form-group">
                  <label>User Name</label>
                  <input
                    type="text"
                    disabled
                    className={`form-control ${
                      errors.username ? "is-invalid" : ""
                    }`}
                    {...register("username")}
                    placeholder="User Name"
                  />
                  <div className="invalid-feedback error">
                    {errors.username?.message}
                  </div>
                </div>
              </div>

              <div className="col-6 my-2">
                <div className="form-group">
                  <label>ZIP Code</label>
                  <input
                    type="text"
                    className={`form-control ${
                      errors.pincode ? "is-invalid" : ""
                    }`}
                    // value={zipCodeVal}
                    {...register("pincode")}
                    onChange={handleZipCodeChange}
                    placeholder="-- -- --"
                  />
                  <div className="invalid-feedback error">
                    {errors.pincode?.message}
                  </div>
                  {customErrorMessage && (
                    <div className="text-danger mt-1">{customErrorMessage}</div>
                  )}
                </div>
              </div>

              <div className="col-12 my-2 text-end">
                <button
                  type="submit"
                  className="btn-create-primary px-3 w-auto my-1 py-2"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </div>
    </Modal>
  );
}
