import React from "react";
import {
  analyticsRoutes,
  performanceRoutes,
  mainRoutes as routes,
} from "config/routing";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { checkActiveRoute } from "helpers/CommonHelper";
import {
  AnalyticsIcon,
  EarningIcon,
  NextArrow,
  PerformanceIcon,
  RecipeVaultIcon,
  TopbarSearchIcon,
} from "../Icons";

interface NavBarLinksProps {
  closeButtonRef?: React.RefObject<HTMLButtonElement>;
}

const NavbarLinks: React.FC<NavBarLinksProps> = ({ closeButtonRef }) => {
  const location = useLocation();

  const navigate = useNavigate();
  const { t } = useTranslation();
  const pathname: string = location.pathname;

  const handleLinkClick = (): void => {
    if (closeButtonRef && closeButtonRef.current) {
      closeButtonRef.current.click();
    }
  };

  return (
    // <div className="bg-primery mein-menu">
    //   <div className="pt-1">
    <>
      <ul className="nav nav-pills" id="menu">
        <li className="nav-item">
          <div className="form-group d-flex align-items-center">
            <img
              src={RecipeVaultIcon}
              className="shadow-sm"
              width="20"
              height="20"
            />
            <a href="#" className="nav-link align-middle ps-1 pe-0 py-1">
              <span>Recipes Vault</span>
            </a>
          </div>

          <ul className="nav pb-3 ps-1 d-block" id="menu">
            <li className="nav-item">
              <div className="form-group d-flex align-items-center">
                <img src={NextArrow} />
                <Link
                  onClick={handleLinkClick}
                  to={routes.recipeVault.path}
                  className={`nav-link align-middle ps-1 pe-0 py-1 d-sm-inline ${checkActiveRoute(
                    pathname,
                    [routes.recipeVault.path],
                    false,
                    true
                  )}`}
                >
                  {t("active_recipes")}
                </Link>
              </div>
            </li>
            <li className="nav-item">
              <div className="form-group d-flex align-items-center">
                <img src={NextArrow} />
                <Link
                  onClick={handleLinkClick}
                  to={routes.publishedRecipe.path}
                  className={`nav-link align-middle ps-1 pe-0 py-1 d-sm-inline ${checkActiveRoute(
                    pathname,
                    [routes.publishedRecipe.path],
                    false,
                    true
                  )}`}
                >
                  {t("published_recipes")}
                </Link>
              </div>
            </li>
            <li className="nav-item">
              <div className="form-group d-flex align-items-center">
                <img src={NextArrow} />
                <Link
                  to={routes.savedRecipePage.path}
                  className={`nav-link align-middle ps-1 pe-0 py-1 d-sm-inline ${checkActiveRoute(
                    pathname,
                    [routes.savedRecipePage.path],
                    false,
                    true
                  )}`}
                >
                  <span>Saved Recipes</span>
                </Link>
              </div>
            </li>
            <li className="nav-item">
              <div className="form-group d-flex align-items-center">
                <img src={NextArrow} />
                <Link
                  onClick={handleLinkClick}
                  to={routes.testingRecipe.path}
                  className={`nav-link align-middle ps-1 pe-0 py-1 d-sm-inline ${checkActiveRoute(
                    pathname,
                    [routes.testingRecipe.path],
                    false,
                    true
                  )}`}
                >
                  {t("Review and Feedback")}
                </Link>
                {/* <span>Review and Feedback </span> */}
              </div>
            </li>
          </ul>
        </li>

        <li className="nav-item">
          <div className="form-group d-flex align-items-center">
            <img
              src={PerformanceIcon}
              className="shadow-sm"
              width="20"
              height="20"
            />
            <Link
              to={routes.performance.path}
              className={`nav-link align-middle d-sm-inline ${checkActiveRoute(
                pathname,
                [routes.performance.path],
                false,
                true
              )}`}
            >
              {t("performance")}
            </Link>
            {/* <span className="px-2 d-none d-sm-inline">Recipe Vault</span> */}
          </div>

          <ul className="nav pb-3 ps-1 d-block" id="menu">
            <li className="nav-item">
              <div className="form-group d-flex align-items-center">
                <img src={NextArrow} />
                <Link
                  to={
                    routes.performance.path +
                    "/" +
                    performanceRoutes.overview.path
                  }
                  className={`nav-link align-middle ps-1 pe-0 py-1 ${checkActiveRoute(
                    pathname,
                    [performanceRoutes.overview.path]
                  )}`}
                >
                  <span>Overview</span>
                </Link>
              </div>
            </li>
            <li className="nav-item">
              <div className="form-group d-flex align-items-center">
                <img src={NextArrow} />
                <Link
                  to={
                    routes.performance.path + "/" + performanceRoutes.views.path
                  }
                  className={`nav-link align-middle ps-1 pe-0 py-1 ${checkActiveRoute(
                    pathname,
                    [performanceRoutes.views.path]
                  )}`}
                >
                  <span>Views</span>
                </Link>
              </div>
            </li>
            <li className="nav-item">
              <div className="form-group d-flex align-items-center">
                <img src={NextArrow} />
                <Link
                  to={
                    routes.performance.path + "/" + performanceRoutes.saves.path
                  }
                  className={`nav-link align-middle ps-1 pe-0 py-1 ${checkActiveRoute(
                    pathname,
                    [performanceRoutes.saves.path]
                  )}`}
                >
                  <span>Saves</span>
                </Link>
              </div>
            </li>
            <li className="nav-item">
              <div className="form-group d-flex align-items-center">
                <img src={NextArrow} />
                <Link
                  to={
                    routes.performance.path +
                    "/" +
                    performanceRoutes.engagement.path
                  }
                  className={`nav-link align-middle ps-1 pe-0 py-1 ${checkActiveRoute(
                    pathname,
                    [performanceRoutes.engagement.path]
                  )}`}
                >
                  <span>Engagement</span>
                </Link>
              </div>
            </li>
          </ul>
        </li>
        <li className="nav-item">
          <div className="form-group d-flex align-items-center">
            <img
              src={AnalyticsIcon}
              className="shadow-sm"
              width="20"
              height="20"
            />
            <Link
              onClick={handleLinkClick}
              to={routes.analytics.path}
              className={`nav-link align-middle d-sm-inline ${checkActiveRoute(
                pathname,
                [routes.analytics.path],
                true
              )}`}
            >
              {t("analytics")}
            </Link>
            {/* <span className="px-2 d-none d-sm-inline">Recipe Vault</span> */}
          </div>

          <ul className="nav pb-3 ps-1 d-block" id="menu">
            <li className="nav-item">
              <div className="form-group d-flex align-items-center">
                <img src={NextArrow} />
                <Link
                  to={
                    routes.analytics.path +
                    "/" +
                    analyticsRoutes.demographics.path
                  }
                  className={`nav-link align-middle ps-1 pe-0 py-1 ${checkActiveRoute(
                    pathname,
                    [analyticsRoutes.demographics.path]
                  )}`}
                >
                  <span>Demographics</span>
                </Link>
              </div>
            </li>
            <li className="nav-item">
              <div className="form-group d-flex align-items-center">
                <img src={NextArrow} />
                <Link
                  to={
                    routes.analytics.path +
                    "/" +
                    analyticsRoutes.consumptionTrends.path
                  }
                  className={`nav-link align-middle ps-1 pe-0 py-1 ${checkActiveRoute(
                    pathname,
                    [analyticsRoutes.consumptionTrends.path]
                  )}`}
                >
                  <span>Consumption Trends</span>
                </Link>
              </div>
            </li>
            <li className="nav-item">
              <div className="form-group d-flex align-items-center">
                <img src={NextArrow} />
                <Link
                  to={
                    routes.analytics.path +
                    "/" +
                    analyticsRoutes.consumerBehavior.path
                  }
                  className={`nav-link align-middle ps-1 pe-0 py-1 ${checkActiveRoute(
                    pathname,
                    [analyticsRoutes.consumerBehavior.path]
                  )}`}
                >
                  <span>Consumer Behavior</span>
                </Link>
              </div>
            </li>
          </ul>
        </li>
        <li className="nav-item">
          <div className="form-group d-flex align-items-center">
            <img src={EarningIcon} width="20" height="20" />
            <Link
              onClick={handleLinkClick}
              to={routes.testingRecipe.path}
              className={`nav-link align-middle d-sm-inline ${checkActiveRoute(
                pathname,
                [routes.testingRecipe.path],
                false,
                true
              )}`}
            >
              {t("review_and_feedback")}
            </Link>
            {/* <span className="px-2 d-none d-sm-inline">Recipe Vault</span> */}
          </div>
        </li>
      </ul>
    </>
    // <ul className="nav flex-column">
    //   <>
    //     <li className="nav-item" key="admin_dashboard">
    //       <Link
    //         onClick={handleLinkClick}
    //         to={routes.adminDashboard.path}
    //         className={`nav-link text-light ${checkActiveRoute(
    //           pathname,
    //           [routes.adminDashboard.path],
    //           false,
    //           true
    //         )}`}
    //       >
    //         {t("dashboard")}
    //       </Link>
    //     </li>
    //   </>
    // </ul>
    //   </div>
    // </div>
  );
};

export default NavbarLinks;
