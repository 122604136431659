import { BlobServiceClient } from "@azure/storage-blob";
import {
  AZURE_COMPANY_FOLDER,
  AZURE_CONTAINER_NAME,
  AZURE_PROFILE_FOLDER,
  AZURE_RECIPE_FOLDER,
  AZURE_SAS_TOKEN,
  AZURE_STORAGE_ACCOUNT_NAME,
} from "config/app.config";
import { decryptText } from "./CommonHelper";
const storageAccountName = decryptText(AZURE_STORAGE_ACCOUNT_NAME);
const containerName = decryptText(AZURE_CONTAINER_NAME);
const sasToken = decryptText(AZURE_SAS_TOKEN);
const azureAccountURL = `https://${storageAccountName}.blob.core.windows.net/`;
const rootPath = `public/upload`;
export const uploadFileToAzure = async (type: string, file: any) => {
  let folder = "images";
  switch (type) {
    case "profile":
      folder = AZURE_PROFILE_FOLDER;
      break;
    case "company":
      folder = AZURE_COMPANY_FOLDER;
      break;
    case "recipe":
      folder = AZURE_RECIPE_FOLDER;
      break;
    default:
      folder = "images";
  }
  const folderPath: string = rootPath + "/" + folder;

  try {
    const blobServiceClient = new BlobServiceClient(
      `${azureAccountURL}?${sasToken}`
    );
    const containerPath = `${containerName}/${folderPath}`;
    const containerClient = blobServiceClient.getContainerClient(
      `${containerPath}`
    );
    let fileName = file.name;
    const fileExtension = fileName.split(".").pop(); // Get the file extension
    const timestamp = new Date().getTime();
    fileName = `${type}_${timestamp}.${fileExtension}`;
    const blobClient = containerClient.getBlobClient(fileName);
    const blockBlobClient = blobClient.getBlockBlobClient();
    const result = await blockBlobClient.uploadData(file, {
      blockSize: 4 * 1024 * 1024,
      concurrency: 20,
      onProgress: (ev: any) => console.log(ev),
    });
    // console.log(result);
    // console.log(`${azureAccountURL}${containerPath}/${fileName}`);
    // console.log(`Upload of file '${fileName}' completed`);
    return {
      status: true,
      fileName: `${folderPath}/${fileName}`,
    };
  } catch (err) {
    return {
      status: false,
      message: err,
    };
  }
};
export const uploadBase64ToAzure = async (type: string, base64Data: string) => {
  let folder = "images";
  switch (type) {
    case "profile":
      folder = AZURE_PROFILE_FOLDER;
      break;
    case "company":
      folder = AZURE_COMPANY_FOLDER;
      break;
    case "recipe":
      folder = AZURE_RECIPE_FOLDER;
      break;
    default:
      folder = "images";
  }
  const folderPath = `${rootPath}/${folder}`;

  try {
    // Extract MIME type from Base64 string
    console.log(type, base64Data);

    const mimeMatch = base64Data.match(/^data:(.*?);base64,/);
    if (!mimeMatch) throw new Error("Invalid Base64 format");
    console.log(mimeMatch);

    const mimeType = mimeMatch[1]; // e.g., "image/jpeg"
    const fileExtension = mimeType.split("/")[1]; // e.g., "jpeg"

    // Decode Base64 string to binary data
    const byteCharacters = atob(base64Data.split(",")[1]); // Remove 'data:image/jpeg;base64,' prefix
    const byteNumbers = new Array(byteCharacters.length)
      .fill(0)
      .map((_, i) => byteCharacters.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);
    const blobData = new Blob([byteArray], { type: mimeType });

    // Initialize BlobServiceClient
    const blobServiceClient = new BlobServiceClient(
      `${azureAccountURL}?${sasToken}`
    );

    // Get container client
    const containerClient = blobServiceClient.getContainerClient(containerName);

    // Prepare file name
    const timestamp = Date.now();
    const fileName = `${type}_${timestamp}.${fileExtension}`;

    // Upload blob
    const blobClient = containerClient.getBlockBlobClient(
      `${folderPath}/${fileName}`
    );
    const uploadOptions = {
      blockSize: 4 * 1024 * 1024, // 4MB block size
      concurrency: 20,
      onProgress: (ev: any) => console.log(ev),
    };

    const result = await blobClient.uploadData(blobData, uploadOptions);
    console.log(`Upload of file '${fileName}' completed with result:`, result);

    // Return success response
    return {
      status: true,
      fileName: `${folderPath}/${fileName}`,
    };
  } catch (err: any) {
    console.error("Error uploading file to Azure:", err);
    return {
      status: false,
      message: err.message || "File upload failed",
    };
  }
};
export const getAzureFilePublicUrl: (keyFile: string) => string = (keyFile) => {
  const filePath = `${containerName}/${keyFile}`;
  const url = `${azureAccountURL}${filePath}`;
  return url;
};
