import { NotificationManager } from "react-notifications";
export const successMessage = (msg: string, title = "Success"): void => {
  NotificationManager.success(msg, title);
};

export const errorMessage = (msg: string, title = "Error"): void => {
  NotificationManager.error(msg, title);
};

export const infoMessage = (msg: string, title = "Info"): void => {
  if (title == "no_title") {
    title = "";
  }
  NotificationManager.info(msg, title);
};
