import { contentPageRoutes, homePageRoutes } from "config/routing";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
  const { t } = useTranslation();
  return (
    <footer className="footer mt-auto py-2 bg-footer w-100 position-fixed bottom-0 border">
      <div className="container">
        <div className="row text-center">
          <div className="col-12">
            {/* <div className="footer-menu"> */}
            <Link
              to={contentPageRoutes.AboutUsPage.path}
              className="fw-medium text-primary"
            >
              {t("about_us")}
            </Link>{" "}
            |
            <Link
              to={contentPageRoutes.SupportPage.path}
              className="fw-medium text-primary"
            >
              {t("support")}
            </Link>{" "}
            |
            <Link
              to={contentPageRoutes.BecomeAPaidTester.path}
              className="fw-medium text-primary"
            >
              {t("become_a_paid_tester")}
            </Link>{" "}
            |
            <Link
              to={contentPageRoutes.JoinInfluencerTeam.path}
              className="fw-medium text-primary"
            >
              {t("join_influencer_team")}
            </Link>
            {/* </div> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
