import { CRYPTO_SECRET_KEY } from "config/app.config";
import CryptoJS from "crypto-js";
import React, { useEffect, useState } from "react";

export const encryptText = (message: any): string => {
  message = message?.toString();
  const cipherText = CryptoJS.AES.encrypt(message, CRYPTO_SECRET_KEY);
  return encodeURIComponent(cipherText.toString());
};

export const decryptText = (message: any): string => {
  const bytes = CryptoJS.AES.decrypt(
    decodeURIComponent(message),
    CRYPTO_SECRET_KEY
  );
  return bytes.toString(CryptoJS.enc.Utf8);
};
export const routeScrollToTop = (): void => {
  // called on route change
  window.scrollTo(0, 0);
};
export const isNullOrEmptyObject = (obj: any): boolean => {
  return (
    obj === null || (typeof obj === "object" && Object.keys(obj).length === 0)
  );
};
export const checkActiveRoute = (
  activeRoute: string,
  routeList: string[],
  haveChild = false,
  matchExactRoute = false
): string => {
  let className: any = "";

  if (!matchExactRoute) {
    const paths: string[] = activeRoute.split("/");
    className =
      paths.some((path) => routeList.includes(path)) ||
      activeRoute.includes(routeList[0])
        ? "active"
        : "";
  } else {
    className = routeList.every((route) => route.includes(activeRoute))
      ? "active"
      : "";
  }

  if (haveChild && className === "active") {
    className += " show";
  }

  return className;
};

export const encryptObject = (obj: object): string => {
  const convertedObj = JSON.stringify(obj);
  return CryptoJS.AES.encrypt(convertedObj, CRYPTO_SECRET_KEY).toString();
};

export const decryptObject = (obj: string): object => {
  return JSON.parse(
    CryptoJS.AES.decrypt(obj, CRYPTO_SECRET_KEY).toString(CryptoJS.enc.Utf8)
  );
};
export const getQueryParams = (url: string): object => {
  if (url.indexOf("?") == -1) {
    return {};
  }
  const paramArr = url.slice(url.indexOf("?") + 1).split("&");
  const params: any = {};
  paramArr.map((param) => {
    const [key, val] = param.split("=");
    const queryval = val.replace(/\+/g, "%20");
    params[key] = decodeURIComponent(queryval);
  });
  return params;
};

export const placeParams = (pathRegex: string, params: any): string => {
  let replacedString = pathRegex;
  Object.keys(params).forEach((key: any) => {
    const placeholderRegex = new RegExp(`:${key}(?![\\w-])`, "g");
    replacedString = replacedString.replace(placeholderRegex, params[key]);
  });
  return replacedString;
};
export const generateRandomNumber = (): number => {
  // parseInt(Date.now() * Math.random());
  return Date.now() * Math.random();
};

export const capitalizeWord = (text: string): string => {
  const words = text.split(" ");
  for (let i = 0; i < words.length; i++) {
    if (words[i][0]) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
  }
  return words.join(" ");
};
export const getTruncatedString = (stringVal: string, length = 20) => {
  if (stringVal.length > length)
    stringVal = stringVal.substring(0, length) + "...";
  return stringVal;
};
export const formatNumber = (num: any): string => {
  num = Number(num);
  if (num >= 1e9) {
    return (num / 1e9).toFixed(1).replace(/\.0$/, "") + "B";
  }
  if (num >= 1e6) {
    return (num / 1e6).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1e3) {
    return (num / 1e3).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num.toString();
};
export const convertMinutesToHours = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  if (hours > 0 && remainingMinutes > 0) {
    return `${hours} hrs ${remainingMinutes} mins`;
  } else if (remainingMinutes > 0) {
    return `${remainingMinutes} mins`;
  } else {
    return `${hours} hrs`;
  }
};
export const truncateText = (text: string, wordLimit = 20): string => {
  const words = text.split(" ");
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(" ") + "...";
  }
  return text;
};
export const handleNumberInputToAllowPositiveNumbers = (e: any) => {
  if (e.key === "-" || e.key === "." || e.key === "e") {
    e.preventDefault();
  }
};
