import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AppBetaLogo,
  AppLogo,
  AvatarImg,
  LecuckooIcon,
  MobileBar,
  SignupBanner,
  TopbarSearchIcon,
} from "../Icons";
import { Link, useNavigate } from "react-router-dom";
import { homePageRoutes, loginRoutes } from "config/routing";
import { logoutUser } from "store/actions/AuthAction";
import { Preloader } from "../Preloader";
import ProfileDropdownMenu from "./HeaderMenu/ProfileDropdownMenu";
import { connect } from "react-redux";
import { userProfileSelector } from "components/selectors/authSelectors";
import { RootState } from "store";

const AuthHeader = ({ userProfileInfo }: { userProfileInfo: any }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  return (
    <>
      <div className="container-fluid topbar">
        <Preloader loading={loading} />
        <div className="row align-items-center justify-content-center row">
          <div className="col-6 col-md-6">
            <div className="d-flex align-items-center">
              <div className="mobileonly-bar">
                <img src={MobileBar} />
              </div>
              <div className="col-12 col-md-4 py-2">
                <Link to={homePageRoutes.homePage.path}>
                  {/* <img
                src={AppLogo}
                style={{ width: "15%", minWidth: "10%", height: "100%" }}
              /> */}
                  {/* <img src={AppLogo} /> */}
                  <img src={AppBetaLogo} className="public-brand-logo" />
                </Link>
              </div>
            </div>
          </div>
          {userProfileInfo && (
            <div className="col-6 col-md-6 text-end align-self-center">
              <ProfileDropdownMenu />
            </div>
          )}
        </div>
      </div>
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container-fluid d-block">
          {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button> */}
          {/* <div className="row border-1 border-black border-bottom mx-1">
            <div className="col-7 col-md-8">
              <h2>{t("my_recipe_box")}</h2>
            </div>

            <div className="col-5 col-md-4 text-end">
              <button
                type="button"
                className="btn-create-primary mb-2 px-2 w-auto py-2"
              >
                {t("create_recipe")}
              </button>
            </div>
          </div> */}
        </div>
      </nav>
    </>
  );
};
const mapStateToProps = (state: RootState) => {
  return {
    userProfileInfo: userProfileSelector(state),
  };
};
export default connect(mapStateToProps, {})(AuthHeader);
