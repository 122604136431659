import Footer from "components/_common/Footer";
import Header from "components/_common/Header";
import React from "react";
import { Outlet } from "react-router-dom";

function HomePageLayout() {
  return (
    <>
      <Header showHeaderMenu={true} />
      <main className="pb-5">
        <Outlet />
      </main>
      <Footer />
    </>
  );
}

export default HomePageLayout;
