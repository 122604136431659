import React, { ReactNode, useLayoutEffect } from "react";
import "./App.css";
import { NotificationContainer } from "react-notifications";
import { HelmetProvider } from "react-helmet-async";
import {
  NavigationType,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-notifications/lib/notifications.css";
import "assets/styles/developer.css";
import "assets/styles/theme.css";
import { routeScrollToTop } from "helpers/CommonHelper";
import ErrorBoundary from "components/_common/ErrorBoundary/ErrorBoundary";
import RouteWrapper from "routes/RouteWrapper";
import { SHOW_LAUNCH_PAGE } from "config/app.config";
import LaunchingSoon from "Pages/AllFrontPages/LauchingSoon";

// Type for the Wrapper component props
interface WrapperProps {
  children: ReactNode;
}

const useBackButton = (): boolean => {
  const navType = useNavigationType();
  return navType === NavigationType.Pop;
};

// Wrapper component to handle scroll to top
const Wrapper: React.FC<WrapperProps> = ({ children }) => {
  const location = useLocation();
  const isPop = useBackButton();

  useLayoutEffect(() => {
    // Scroll to top on route change
    routeScrollToTop();
  }, [location.pathname, isPop]);

  return <>{children}</>;
};

const App: React.FC = () => {
  return (
    <HelmetProvider>
      <ErrorBoundary>
        <NotificationContainer />
        <Wrapper>
          {SHOW_LAUNCH_PAGE == "true" ? <LaunchingSoon /> : <RouteWrapper />}
        </Wrapper>
      </ErrorBoundary>
    </HelmetProvider>
  );
};

export default App;
