import React from "react";
import {
  AppLogo,
  LecuckooIcon,
  SignupBanner,
  TopbarSearchIcon,
} from "../Icons";
import { Link } from "react-router-dom";
import { loginRoutes } from "config/routing";

export const AuthFooter = () => {
  return <></>;
};
